<template>
  <div class="page">
      <!--查询框-->
      <div class="search-box section page-header" style="margin-bottom: 1px;height: auto;">
          <el-form ref="searchForm" :inline="true" :model="query" size="medium">
              <el-form-item prop="parkingLotId">
                  <SuggestParking v-model="query.parkingLotId" class="width260" />
              </el-form-item>
              <el-form-item prop="phone">
                  <!--          <SuggestParking v-model="query.parkingLotId" />-->
                  <el-input v-model="query.phone" placeholder="搜索手机号"
                      class="width240 margin-right10 margin-left10" />
              </el-form-item>
              <el-form-item prop="leaseName">
                  <el-select v-model="query.leaseName" clearable placeholder="请选择套餐名称" class="width260">
                      <el-option v-for="item, index in packageList" :key="index" :value=item.id
                                      :label="item.leaseName">
                                  </el-option>
                  </el-select>
              </el-form-item>
              <!-- <el-form-item prop="leaseName">
                  <el-select v-model="query.orderStatus" clearable placeholder="请选择套餐状态" class="width260">
                      <el-option label="正常" value="0"></el-option>
                  </el-select>
              </el-form-item> -->
              <el-form-item prop="payStatus">
                  <el-select v-model="query.payStatus" clearable placeholder="请选择付款状态" class="width260">
                      <el-option label="已支付" value="0"></el-option>
                      <el-option label="未支付" value="1"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item prop="plateNumber">
                  <!-- <NumplateSuggest v-model="query.plateNumber" class="width260" /> -->
                   <el-input v-model="query.plateNumber" placeholder="请输入车牌号" style="width: 200px;"></el-input>
              </el-form-item>
              <el-form-item prop="spaceCode">
                   <el-input v-model="query.spaceCode" placeholder="请输入车位号" style="width: 100px;"></el-input>
              </el-form-item>
              <el-form-item prop="dateType">
                  <el-select class="width120" v-model="query.dateType" placeholder="时间范围">
                      <el-option label="生效时间" value="0"></el-option>
                      <el-option label="到期时间" value="1"></el-option>
                      <!--              <el-option label="驶入驶出" value="3"></el-option>-->
                  </el-select>
                  <!--            :default-time="['00:00:00', '23:59:59']"-->
                  <el-date-picker v-model="query.timeValues" :picker-options="pickerOptions" align="right"
                      end-placeholder="结束日期" range-separator="至" start-placeholder="开始日期" type="daterange"
                      value-format="yyyy-MM-dd">
                  </el-date-picker>
              </el-form-item>
              <el-form-item>
                  <el-button type="primary" @click="doSearch">搜索</el-button>
                  <el-button @click="resetQueryForm">重置</el-button>
              </el-form-item>
          </el-form>
      </div>

     

      <div class="content-box section main-box">
          <div style="display: flex;margin-bottom: 10px;">
              <el-button type="primary" @click="goAdd">新增</el-button>
              <el-button type="primary" @click="handleImport">批量导入</el-button>
          </div>
          <div>
              <!--        v-loading="isLoading"-->
              <el-table :data="dataList" border class="common-table" size="medium" style="width: 100%" height="60vh">
                  <!-- <el-table-column type="selection" width="55"> </el-table-column> -->

                  <el-table-column align="center" label="主车牌" fixed="left" prop="plate" width="130">
                      <template slot-scope="scope">
                          <Numplate v-if="scope.row.plate.firstPlateNumber" :type="scope.row.plate.firstPlateColor ? scope.row.plate.firstPlateColor : '02'">
                              {{ scope.row.plate.firstPlateNumber }}
                          </Numplate>
                      </template>
                  </el-table-column>
                  <el-table-column align="center" label="副车牌" fixed="left" prop="plate" width="130">
                      <template slot-scope="scope">
                          <Numplate v-if="scope.row.plate.secondPlateNumber" :type="scope.row.plate.secondPlateColor ? scope.row.plate.secondPlateColor : '02'">
                              {{ scope.row.plate.secondPlateNumber }}
                          </Numplate>
                      </template>
                  </el-table-column>
                  <el-table-column align="center" label="套餐类型" prop="leaseType" width="100">
                      <template slot-scope="scope">
                          {{ leaseList[scope.row.leaseType] }} 
                      </template>
                  </el-table-column>
                  <el-table-column align="center" label="车场名称" prop="parkingLotName" width="150">
                  </el-table-column>
                  <el-table-column align="center" label="套餐名称" prop="leaseName" width="100">
                  </el-table-column>
                  <el-table-column align="center" label="订单状态" prop="orderStatus" width="180">
                      <template slot-scope="scope">
                          <span>{{ orderStatus[scope.row.orderStatus] }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column align="center" label="付款状态" prop="payStatus" width="100">
                      <template slot-scope="scope">
                          {{ scope.row.payStatus == 0?"已支付":"未支付" }}
                      </template>
                  </el-table-column>
                  <el-table-column align="center" label="车位号" prop="spaceCode" width="70">
                  </el-table-column>
                  <el-table-column align="center" label="绑定手机号" prop="phone" width="130">
                  </el-table-column>
                  
                  <el-table-column align="center" label="有效时段" prop="effectiveTime" width="160">
                  </el-table-column>
                  <el-table-column align="center" label="生效时间" prop="startTime" width="160">
                  </el-table-column>
                  <el-table-column align="center" label="到期时间" prop="endTime" width="160">
                  </el-table-column>
                  <el-table-column align="center" label="创建时间" prop="createTime" width="160">
                  </el-table-column>
                  <el-table-column align="center" label="添加途径" prop="addWay" width="100">
                  </el-table-column>
                  <el-table-column align="center" label="购买数量" prop="buyCount" width="100">
                  </el-table-column>
                  <el-table-column align="center" label="订单金额" prop="orderAmount" width="100">
                  </el-table-column>
                  
                  <el-table-column align="center" label="订单编号" prop="orderId" width="160">
                  </el-table-column>

                  <el-table-column align="center" fixed="right" label="操作" prop="name" width="280px">
                      <template slot-scope="scope">
                          <el-button class="common-text font-size14" size="small" type="text"
                              @click="goDetail(scope.row,1)">续租
                          </el-button>
                          <el-button class="common-text font-size14" size="small" type="text"
                              @click="goDetail(scope.row,0)">编辑
                          </el-button>
                          <el-button class="common-text font-size14" size="small" type="text"
                              @click="goDetail(scope.row,2)">复制
                          </el-button>
                          <!-- <el-button class="common-text font-size14" size="small" type="text"
                              v-if="scope.row.orderStatus != 0"
                              @click="statusOrder(scope.row,0)">启用
                          </el-button> -->
                          <el-button class="common-text font-size14" size="small" type="text"
                              v-if="scope.row.orderStatus == 0"
                              @click="statusOrder(scope.row,1)">停用
                          </el-button>
                      </template>
                  </el-table-column>
              </el-table>
          </div>


          <div class="pagination-box">
              <el-pagination :current-page="pagination.pageNo" :page-size="pagination.pageSize"
                  :total="pagination.totalSize" background layout="sizes, prev, pager, next, jumper"
                  @size-change="handleSizeChange" @current-change="handleCurrentChange">
              </el-pagination>
          </div>
      </div>
      <!-- 批量弹窗 -->
      <el-dialog :append-to-body="true" :visible.sync="importDialogFormVisible" class="common-dialog" title="批量上传"
          width="800px" @close="onBatchUploadClosed">
          <el-form ref="searchForm" :inline="true" :model="model" size="medium">
              <el-form-item prop="parkingLotId">
                  <SuggestParking v-model="model.parkingLotId" class="width260" />
              </el-form-item>
              <el-form-item prop="leaseId">
                  <el-select v-model="model.leaseId" clearable placeholder="请选择套餐" class="width200">
                      <el-option v-for="item, index in packageList" :key="index" :value=item.id
                                      :label="item.leaseName">
                                  </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item>
                  <div style="display: flex;">
                      <el-upload :before-upload="handleBeforeUpload" :http-request="doUpload" :show-file-list="false"
                      accept=".xlsx" action="">
                          <el-button type="primary">上传文件</el-button>
                      </el-upload>
                      <el-button style="margin-left: 10px" @click="downLoad">下载模板</el-button>
                  </div>
                  
              </el-form-item>
              <el-form-item>
                  上传总数量：{{ batchModel.upLoadList.length }}
              </el-form-item>
          </el-form>
          <el-table :data="batchModel.upLoadList" height="40vh" border class="common-table" size="medium" style="width: 100%">
              <!-- <el-table-column type="selection" width="55"> </el-table-column> -->

              <el-table-column align="left" label="车牌号码" prop="firstPlateNumber" width="120">
                  <!-- <template slot-scope="scope">
                      <span v-if="scope.row.plateNumber != ''">
                          <Numplate :type="scope.row.autoType ? scope.row.autoType : '02'">
                              {{ scope.row.plateNumber }}
                          </Numplate>
                      </span>
                  </template> -->
              </el-table-column>
              <el-table-column align="left" label="车牌颜色" prop="firstPlateColor" width="120">
              </el-table-column>
              <el-table-column align="left" label="生效时间" prop="startTime" width="160">
              </el-table-column>
              <el-table-column align="left" label="购买数量" prop="buyCount" width="120">
              </el-table-column>
              <el-table-column align="left" label="联系电话" prop="phone" width="130">
              </el-table-column>
              <el-table-column align="left" label="联系人" prop="contacts" width="120">
              </el-table-column>
          </el-table>
          <div slot="footer" class="dialog-footer" style="justify-content: space-between;display: flex;">
              <el-button type="warning" style="background: rgba(234,85,4,0.08);color: #EA5504;border: none;"
                  @click="clearData">清理上传数据</el-button>
              <div><el-button type="primary" @click="save">保存</el-button>
                  <el-button @click="closeModify">取消</el-button>
              </div>

          </div>
      </el-dialog>
  </div>
</template>

<script>
import SuggestParking from "@/components/SuggestParking";
// import Settlement from "@/components/Settlement";
import Numplate from "@/components/Numplate";
import {  updateVehicleNumPlate, } from "@/api/parkingOrder";
import {
  getLeaseOrderList,
  // downLoadTemplate,
  upLodeFileLeaseOrder,
  batchAddLeaseOrder,
  statusLeaseOrder
} from "@/api/lease";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
// import Passage from "@/components/Passage";
import { dateFormat } from "@/utils/utils";
import { doExport,getLeaseList } from "@/api/common";
import { Message } from "element-ui";

export default {
  name: "long_order",
  mixins: [BaseMixin, Permission],
  data() {
      // const today = dateFormat("yyyy-mm-dd", new Date());
      // const beginDate = today;
      // // const endDate = dateFormat("yyyy-mm-dd HH:MM:SS", new Date());
      // const endDate = today;
      return {
          dataList: [],
          uploadList:[],
          batchModel:{
              upLoadList:[]
          },
          leaseList:{
              "0":"月",
              "1":"季度",
              "2":"半年",
              "3":"年",
          },
          summaryData: {},
          // isLoading: false,
          completeDialogFormVisible: false, //控制完结弹窗
          modifyDialogFormVisible: false, //修改车牌弹窗
          total: "0",
          discountAmountStats: "0",
          receivableAmountStats: "0",
          transactionAmountStats: "0",
          // 完结表单
          completeForm: {
              parkingOrderIntoId: "",
              endTime: "",
              isCalculFee: '0',
          },
          rules: {
              endTime: [
                  { required: true, message: "请选择日期时间", trigger: "change" },
              ],
              isCalculFee: [
                  { required: true, message: "请选择是否有效", trigger: "change" },
              ],
          },
          //修改车牌表单
          modifyForm: {
              parkingOrderIntoId: "",
              parkingSpaceId: "",
              color: "",
              numPlate: "",
              reason: "",
          },
          modifyRules: {
              color: [
                  { required: true, message: "请选择车牌颜色", trigger: "change" },
              ],
              numPlate: [
                  { required: true, message: "请输入车牌号码", trigger: "blur" },
              ],
              reason: [
                  { required: true, message: "请输入修改原因", trigger: "blur" },
                  { max: 100, message: "长度不能超过100个字符", trigger: "blur" },
              ],
          },
          pickerOptions: {
              disabledDate(time) {
                  return time.getTime() < new Date().getTime() - 24 * 3600 * 1000 * 90
                      || time.getTime() > new Date().getTime();
              },
              shortcuts: [
                  {
                      text: "最近一周",
                      onClick(picker) {
                          const end = new Date();
                          const start = new Date();
                          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                          picker.$emit("pick", [start, end]);
                      },
                  },
                  {
                      text: "最近一个月",
                      onClick(picker) {
                          const end = new Date();
                          const start = new Date();
                          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                          picker.$emit("pick", [start, end]);
                      },
                  },
                  {
                      text: "最近三个月",
                      onClick(picker) {
                          const end = new Date();
                          const start = new Date();
                          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                          picker.$emit("pick", [start, end]);
                      },
                  },
              ],
          },
          dialogFormVisible: false, //控制审核弹窗层显示
          photoDialogFormVisible: false,
          orderType: {
              // 0 临停，1白名单，2黑名单，3长租，4预存，5访客，6共享
              0: "临停",
              1: "白名单",
              2: "黑名单",
              3: "长租",
              4: "预存",
              5: "访客",
              6: "共享",
          },
          orderStatus: {
              0: "正常",
              1: "关闭",
              2: "手动关闭"
              // 2: "审核",
              // 3: "复议",
              // "": "正常",
          },
          payStatus: {
              // "": "",
              '0': "欠费",
              '1': "成功",
              '2': "交易取消",
          },
          query: {
              companyName: "", //结算方名称
              parkingLotId: "", //停车场id
              parkingOrderIntoId: "", //订单编号
              parkingOrderType: "", //订单类型
              orderStatus: "", //订单状态
              isArrearage: "", //付款状态
              isPlateNumber: "", //有无车牌
              plateNumber: "", //车牌号id
              parkingLotType: "1", //1-路内查询 0-路外查询
              phoneNumber: "", //手机号
              parkingSpeaceCode: "", //泊位号
              // dateType: "", //驶入驶出状态  0-驶入  1-驶出
              dateType: "0", //时间范围
              timeValues: ['', ''], //时间
              startTime: "",
              endTime: "",
              isExport: "0", //是否导出
              exportSize: 1, //导出条数
          },
          importDialogFormVisible: false, //导出弹窗
          form: {
              exportSize: 1,
          },
          packageList:[],
          model:{
              batchNumber:"",
              parkingLotId:"",
              leaseId:""
          }
      };
  },
  components: {
      SuggestParking,
      // Settlement,
      Numplate,
      // Passage,
  },
  methods: {
      onSubmit() {
          console.log("submit!");
      },
      goAdd(){
          this.$router.push({
              path:"/business_manage/detail_long_order",
          });
      },
      //查看订单详情
      goDetail(item,type) {
          this.$router.push({
              path:"/business_manage/detail_long_order",
              query:{
                  orderId:item.orderId,
                  type:type
              }
          });
      },
      downLoad(){
          this.download('/lease/downLoadTemplate', {
              }, `样例_${new Date().getTime()}.xlsx`)
      },
      onBatchUploadClosed() {
          this.batchModel.upLoadList = [];
          this.model.batchNumber = "";
      },
      //上传
      handleBeforeUpload(file) {
          var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
          const isExcel = testmsg === "xlsx";
          if (!isExcel) {
              this.$message({
                  message: "只能上传.xlsx文件",
                  type: "warning",
              });
              return false;
          } else {
              return true;
          }
      },
      async doUpload(file) {
          // console.log(file);
          this.isUpLoadLoading = true;
          const formData = new FormData();
          formData.append("file", file.file);
          const res = await upLodeFileLeaseOrder(formData);
          this.isUpLoadLoading = false;
          if (res && res.code === 30) {
              console.log(res);
              
              this.model.batchNumber = res.returnObject.batchNumber;
              this.batchModel.upLoadList = res.returnObject.records;
              console.log(this.batchModel.upLoadList);
              
          }
      },
      clearData(){
          this.model.batchNumber = "";
          this.batchModel.upLoadList = [];
      },
      statusOrder(item,status){
          statusLeaseOrder({orderId:item.orderId,status:status}).then(res=>{
              if(res.code == 30){
                  Message.success("操作成功");
                  status == 0 ? item.orderStatus = status : item.orderStatus = 2;
                  
              }else{
                  Message.error("操作失败")
              }
          })
      },
      // 批量导入
      handleImport() {
          this.importDialogFormVisible = true;
      },
      //重置查询条件
      resetQueryForm() {
          // const today = dateFormat("yyyy-mm-dd", new Date());
          // const beginDate = today;
          // const endDate = today;
          this.query = {
              companyName: "", //结算方名称
              parkingLotId: "", //停车场id
              parkingOrderIntoId: "", //订单编号
              parkingOrderType: "", //订单类型
              orderStatus: "", //订单状态
              isArrearage: "", //付款状态
              isPlateNumber: "", //有无车牌
              plateNumber: "", //车牌号id
              parkingLotType: "1", //路内查询
              phoneNumber: "", //手机号
              parkingSpeaceCode: "", //泊位号
              // dateType: "", //驶入驶出状态  0-驶入  1-驶出
              dateType: "0", //时间范围
              timeValues: ['', ''], //时间
              startTime: "",
              endTime: "",
              isExport: "0", //是否导出
              exportSize: 1, //导出条数
          };
          this.query.timeValues = [];
          this.pagination.pageSize = 10;
          // this.getNowDate();
          this.doSearch();
      },
      doSearch() {
          this.pagination.pageNo = 1;
          this.search();
      },
      async search() {
          this.isLoading = true;
          const params = this.paramFormat(this.query);
          if (this.query.timeValues != null) {
              if (this.query.dateType == '') {
                  this.$message.warning('请选择时间范围后重试')
                  return false
              }
              params["startTime"] = this.query.timeValues[0] || "";
              params["endTime"] = this.query.timeValues[1] || "";
          }
          const res = await getLeaseOrderList(params);
          // const res = await getParkingOrderByPage({
          //   pageNo: this.pagination.pageNo,
          //   pageSize: this.pagination.pageSize,
          //   userId: "1",
          // });
          this.isLoading = false;
          if (res && res.code === 30 && res.result) {
              const returnObject = res.returnObject;
              if (returnObject !== "") {
                  this.dataList = returnObject.records;
                  this.summaryData = returnObject
                  this.pagination.totalSize = returnObject.total;
                  this.pagination.totalPages = returnObject.pages;
                  this.total = returnObject.total;
                  this.form.exportSize = returnObject.total;
              }
          }
      },
      //完结弹出窗
      complete(item) {
          this.completeDialogFormVisible = true;
          this.completeForm.parkingOrderIntoId = item;
      },
      //关闭完结弹窗清空表单
      completeDialogBeforeClosed(done) {
          this.$refs.completeForm.resetFields();
          done();
      },
        //保存模板数据
        save() {
          if(this.model.batchNumber){
              this.isLoading = true;
              batchAddLeaseOrder(this.model).then((res) => {
                  this.isLoading = false;
                  if (res && res.code === 30 && res.result) {
                      Message.success("保存成功！");
                      this.importDialogFormVisible = false;
                      this.batchModel.upLoadList = [];
                      this.doSearch();
                  }
              });
          }else{
              Message.info("请先上传");
          }
          
      },
      //修改车牌提交
      modifySubmit() {
          this.$refs.modifyForm.validate((valid) => {
              if (valid) {
                  const parkingOrderIntoId = this.modifyForm.parkingOrderIntoId;
                  const color = this.modifyForm.color;
                  const numPlate = this.modifyForm.numPlate;
                  const reason = this.modifyForm.reason;
                  const parkingSpaceId = this.modifyForm.parkingSpaceId;

                  updateVehicleNumPlate(
                      parkingOrderIntoId,
                      parkingSpaceId,
                      numPlate,
                      color,
                      reason
                  ).then((res) => {
                      if (res && res.code === 30 && res.result) {
                          this.$message({
                              message: "修改车牌成功",
                              type: "success",
                          });
                          this.modifyDialogFormVisible = false;
                          this.doSearch();
                      }
                  });
              } else {
                  console.log("error submit!!");
                  return false;
              }
          });
      },
      //修改车牌弹窗关闭时清理表单
      modifyDialogBeforeClosed(done) {
          this.$refs.modifyForm.resetFields();
          done();
      },
      //关闭修改车牌
      closeModify() {
          this.importDialogFormVisible = false;
          this.$refs.modifyForm.resetFields();
      },
      // handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      // },
      // handleCurrentChange(val) {
      //   console.log(`当前页: ${val}`);
      // },
      //修改车牌弹窗
      modifyLicensePlate(parkingOrderIntoId, parkingSpaceId) {
          this.modifyDialogFormVisible = true;
          this.modifyForm.parkingOrderIntoId = parkingOrderIntoId;
          this.modifyForm.parkingSpaceId = parkingSpaceId;
      },
      //导出弹窗
      exportData() {
          this.importDialogFormVisible = true;
      },
      //确认导出
      confirmExport() {
          const loading = this.$loading({
              lock: true,
              text: "正在导出",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0)",
          });
          const params = this.paramFormat(this.query);
          if (this.query.timeValues.length > 0) {
              params["startTime"] = this.query.timeValues[0];
              params["endTime"] = this.query.timeValues[1];
          }
          params["isExport"] = "1";
          params["exportSize"] = this.form.exportSize;
          // const res = searchIncomeFlow(params);
          // var text = window.location.hostname;
          // console.log(text);
          // window.open("http://172.16.69.129:81" + "/ParkingManagermentController/getEsParkingOrderRecord?" + objectToQueryString(params));
          doExport(
              "/ParkingManagermentController/getEsParkingOrderRecord",
              params,
              "路内停车订单.xlsx"
          );
          loading.close();
          this.importDialogFormVisible = false;

          // if (res && res.code === 30 && res.result) {
          //   loading.close();
          //   this.importDialogFormVisible = false;
          // }
      },
      //获取当前时间
      getNowDate() {
          const beginToday = dateFormat("yyyy-mm-dd", new Date());
          const beginDate = beginToday;
          const endToday = dateFormat("yyyy-mm-dd", new Date());
          const endDate = endToday;
          this.query.timeValues = [beginDate, endDate];
      },
  },
  async mounted(){
      const res = await getLeaseList({ pageNo: 1, pageSize: 100 });
      if (res && res.code == 30) {
          this.packageList = res.returnObject;
      }
  }
};
</script>

<style lang="less" scoped>
.photoShow {
  display: flex;
  flex-direction: row;

  .photo-width {
      /deep/ .el-carousel__container {
          width: 430px;
      }
  }

  .driveInPhoto {
      margin: 0 34px;
  }

  .driveOutPhoto {
      margin: 0 26px;
  }
}
.main-box{
  margin-top: 20px;
}
/deep/ .el-button--text {
  color: #0768FD !important;
}
</style>